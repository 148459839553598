import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import {Alert} from "react-bootstrap";
import App from "./App";
import "./App.scss";
import configureStore from "./_redux/redux_store";
import {isIE} from 'react-device-detect';
import HubspotSupportWidget from "./MainPage/HubspotSupportWidget";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


export const store = configureStore();

const renderApp = () =>
  render(
    <Provider store={store}>
      {isIE && <Alert variant={'danger'} >
        It seems you are using Internet Explorer. It is advised to use the latest version of Google Chrome, Mozilla Firefox, or Microsoft Edge when using this website.
      </Alert>}
      <App />
      <HubspotSupportWidget />
    </Provider>,
    document.getElementById("root")
  );

// Hot Reloading
// if (process.env.NODE_ENV !== 'production' && module.hot) {
//   module.hot.accept('./App', renderApp)
// }

renderApp();
serviceWorkerRegistration.register();