import React, {useEffect} from "react";
import { Router, Route, Switch } from "react-router-dom";

import { historyConfig } from './_config/history.config';
import "./App.scss";

import asyncComponent from "./_helpers/AsyncComponent";
import RequireAuth from "./require_auth";
import OauthRedirect from "./oauth_redirect";
import TermsAndConditions from "./terms_and_conditions";
import CeraVeRedirect from "./CeraVeRedirect";
import ExternalVideoPage from "./MainPage/Body/EnrichmentCenter/ExternalVideoPage/external_video_page.jsx"

const AsyncLogin = asyncComponent(() => import("./LoginPage"));
const AsyncConfirmPassword = asyncComponent(() => import("./LoginPage/confirm_password_page"));
const AsyncMain = asyncComponent(() => import("./MainPage"));
const AsyncAdmin = asyncComponent(() => import("./AdminPage"));
const AsyncForgotPassword = asyncComponent(() => import("./LoginPage/forgot_password_page"));
const AsyncResetPassword = asyncComponent(() => import("./LoginPage/reset_password_page"));
const AsyncCreateUser = asyncComponent(() => import("./LoginPage/CreateUserPage/create_user_page"));
const AsyncRemindMeLater = asyncComponent(() => import("./LoginPage/RemindMeLater/remind_me_later"));
const AsyncRegistration = asyncComponent(() => import("./LoginPage/RegistrationPage"));
const AsyncReview = asyncComponent(() => import("./LoginPage/RegistrationPage/review_page"));
const AsyncBilling = asyncComponent(() => import("./LoginPage/RegistrationPage/billing_page"))
const AsyncPilotSurveyPage = asyncComponent(() => import("./PilotSurveyPage"));
const AsyncSurveyCompletionPage = asyncComponent(() => import("./PilotSurveyPage/survey_completion"));
const AsyncSingleSignOnPage = asyncComponent(() => import("./LoginPage/single_sign_on_page"));
const AsyncResendSurveyPage = asyncComponent(() => import("./LoginPage/resend_survey_page"));

const App = () => {
  useEffect(() => {
    console.log('COMMIT ID:', process.env.REACT_APP_COMMIT_ID);
  }, []);
  return (
    <Router history={historyConfig}>
      <Switch>
        <Route path="/login">
          <AsyncLogin/>
        </Route>

        <Route path="/forgot-password">
          <AsyncForgotPassword/>
        </Route>
        <Route path="/reset-password">
          <AsyncResetPassword/>
        </Route>

        <Route path="/resend-survey">
          <AsyncResendSurveyPage/>
        </Route>

        {/*specialized route for the 3m modal workflow*/}
        <Route path="/remind-me-later/:auth_token">
          <AsyncRemindMeLater/>
        </Route>

        <Route path="/confirm-password/:token/:email">
          <AsyncConfirmPassword/>
        </Route>

        <Route path="/register" >
          <AsyncRegistration/>
        </Route>

        <Route path="/billing" >
          <AsyncBilling/>
        </Route>

        <Route path={"/review_registration"}>
          <AsyncReview/>
        </Route>

        {/*
          specialized user creation page for MUSC.
          block_id is hardcoded for specific survey event and
          will need to be updated for future survey events.
          */}
        <Route path="/create-ec-user/MUSC">
          <AsyncCreateUser org_name={"MUSC"} practice_id={46} block_id={72} is_musc_org={true} />
        </Route>

        {/*
          specialized user creation page for Prisma.
          block_id is hardcoded for specific survey event and
          will need to be updated for future survey events.
          */}
        <Route path="/create-ec-user/Prisma">
          <AsyncCreateUser org_name={"Prisma"} practice_id={47} block_id={73} />
        </Route>

        {/*
          specialized user creation page for ANA.
          block_id is hardcoded for specific survey event and
          will need to be updated for future survey events.
          */}
        {/* TODO removed need to hardcode block id*/}
        <Route path="/create-ec-user/ANA">
          <AsyncCreateUser org_name={"ANA"} practice_id={54} block_id={123} is_ana_org={true} />
        </Route>

        {/*
          specialized user creation page for CPP.
          block_id is hardcoded for specific survey event and
          will need to be updated for future survey events.
          */}
        <Route path="/create-ec-user/CPP">
            <AsyncCreateUser is_cpp_org={true} block_id={121} practice_id={39} />
        </Route>

        {/*
          specialized user creation page for Chamberlain.
          block_id is hardcoded for specific survey event and
          will need to be updated for future survey events.
          */}
        <Route path="/create-ec-user/chamberlain">
          <AsyncCreateUser org_name={"Chamberlain University"} practice_id={77} block_id={114} is_chamberlain_org={true} />
        </Route>

        {/*
          specialized user creation page for LGH.
          block_id is hardcoded for specific survey event and
          will need to be updated for future survey events.
          */}
        <Route path="/create-ec-user/LGH">
          <AsyncCreateUser org_name={"Lancaster General Health"} practice_id={78} block_id={126} />
        </Route>


        {/*
          Multiple registration pages for CeraVe (with varying practices) to track user creation origin using QR codes.
          */}

        {/*<Route path="/create-ec-user/CeraVe/Vermont">*/}
        {/*  <AsyncCreateUser org_name={"CeraVe"} practice_id={71} block_id={104} is_cerave_org={true} />*/}
        {/*</Route>*/}

        {/*<Route path="/create-ec-user/CeraVe/CPR">*/}
        {/*  <AsyncCreateUser org_name={"CeraVe"} practice_id={58} block_id={104} is_cerave_org={true} />*/}
        {/*</Route>*/}

        {/*<Route path="/create-ec-user/CeraVe/LNA">*/}
        {/*  <AsyncCreateUser org_name={"CeraVe"} practice_id={61} block_id={104} is_cerave_org={true} />*/}
        {/*</Route>*/}


        {/*<Route path="/create-ec-user/CeraVe/ANCC">*/}
        {/*  <AsyncCreateUser org_name={"CeraVe"} practice_id={62} block_id={104} is_cerave_org={true} />*/}
        {/*</Route>*/}


        {/*<Route path="/create-ec-user/CeraVe/TNA">*/}
        {/*  <AsyncCreateUser org_name={"CeraVe"} practice_id={63} block_id={104} is_cerave_org={true} />*/}
        {/*</Route>*/}


        {/*<Route path="/create-ec-user/CeraVe/SOM">*/}
        {/*  <AsyncCreateUser org_name={"CeraVe"} practice_id={64} block_id={104} is_cerave_org={true} />*/}
        {/*</Route>*/}

        {/*<Route path="/create-ec-user/CeraVe/HCA">*/}
        {/*  <AsyncCreateUser org_name={"CeraVe"} practice_id={65} block_id={104} is_cerave_org={true} />*/}
        {/*</Route>*/}

        {/*<Route path="/create-ec-user/CeraVe/SCO">*/}
        {/*  <AsyncCreateUser org_name={"CeraVe"} practice_id={66} block_id={104} is_cerave_org={true} />*/}
        {/*</Route>*/}

        {/*<Route path="/create-ec-user/CeraVe/Dallas">*/}
        {/*  <AsyncCreateUser org_name={"CeraVe"} practice_id={67} block_id={104} is_cerave_org={true} />*/}
        {/*</Route>*/}

        {/*<Route path="/create-ec-user/CeraVe/ASNA">*/}
        {/*  <AsyncCreateUser org_name={"CeraVe"} practice_id={69} block_id={104} is_cerave_org={true} />*/}
        {/*</Route>*/}

        {/*<Route path="/create-ec-user/CeraVe/MSNA">*/}
        {/*  <AsyncCreateUser org_name={"CeraVe"} practice_id={70} block_id={104} is_cerave_org={true} />*/}
        {/*</Route>*/}

        {/*<Route*/}
        {/*  path="/create-ec-user/CeraVe/:id(\d+)"*/}
        {/*  render={routeProps => <AsyncCreateUser {...routeProps} org_name={"CeraVe"} practice_id={parseInt(routeProps.match.params.id)} block_id={104} is_cerave_org={true} />}*/}
        {/*/>*/}

        <Route path="/create-ec-user/CeraVe">
          <CeraVeRedirect/>
        </Route>

        {/*
        route for free promotional users
        TODO rake task that disables users 1 year after their creation date
        */}
        <Route path="/create-ec-user/promotion">
          <AsyncCreateUser practice_id={91} />
        </Route>

        {/*
          generic user creation page for Enrichment Center only.
          mainly used for the a la carte selling of the Enrichment Center through authorized.net
           */}
        <Route path="/create-ec-user/:org_name?">
          <AsyncCreateUser practice_id={43} />
        </Route>

        {/*
          specialized user creation page for LocumTenens.com.
          block_id is hardcoded for specific survey event and
          will need to be updated for future survey events.
          */}
        {/*<Route*/}
        {/*  path="/create-lt-user"*/}
        {/*  render={routeProps => (<AsyncCreateUser {...routeProps} org_name={"LocumTenens.com"} practice_id={40} block_id={83}/>)}*/}
        {/*/>*/}
        <Route exact path={["/complementary_assessment","/assessment"]}>
          <AsyncPilotSurveyPage/>
        </Route>

        <Route path={["/complementary_assessment/:group/:email?","/assessment/:group/:email?"]}>
          <AsyncPilotSurveyPage/>
        </Route>

        <Route path={["/complementary_assessment_completed","/assessment_completed"]}>
          <AsyncSurveyCompletionPage/>
        </Route>

        <Route path={"/oauth"}>
          <OauthRedirect/>
        </Route>

        <Route path={"/single-sign-on"}>
          <AsyncSingleSignOnPage/>
        </Route>


        <Route path={"/terms-and-conditions"}>
          <TermsAndConditions/>
        </Route>

        <Route path={"/external_video_page"}>
          <ExternalVideoPage/>
        </Route>

        <Route path="/admin"
               render={() => (
                 <RequireAuth>
                   <AsyncAdmin />
                 </RequireAuth>
               )}
        />

        <Route path={"/"}
               render={() => (
                 <RequireAuth>
                   <AsyncMain />
                 </RequireAuth>
               )}
        />


      </Switch>
    </Router>
  );
}

export default App;